import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduction`}</h2>
    <p>{`Designing access permission for the SaaS app is not an easy task, but it’s important to help build your product more manageable and scalable. Designing a user management system would help protect privacy concerns and data safety, to make it more efficient for different types of users.`}</p>
    <div className="divi" />
    <h2>{`User management`}</h2>
    <p>{`User management plays an important role in defining and controlling the access for different users within a system, this would help increase the security access to SaaS product that is essentials across the B2B company. User management enables personalized access to the end-users by adjusting the access based on each need. By personalizing the access, users would have the most relevant resources that they need to do their tasks.`}</p>
    <h2>{`Role management`}</h2>
    <p>{`The most popular model of user management is RBAC (Role Based Access Control), Different from ACL (Access Control List) which explicitly lists all available options directly to individual users, the RBAC model will use roles to categorize and manage permissions that will be applied on each role. The RBAC model will help to organize the permissions easier and faster by using Roles as a service hub between users and permissions, so admins would only need to assign roles to each user and can configure the permissions that the roles would have.`}</p>
    <figure>{`
  `}<img parentName="figure" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/user-management/comparison-model-acl-vs-rbac.png",
        "alt": "Comparison model ACL vs RBAC"
      }}></img>{`
  `}<figcaption parentName="figure" {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`Comparison model ACL vs RBAC`}</figcaption>
    </figure>
    <p>{`Beyond the basic RBAC model, there are also user group permission to help organize a large number of users or companies that will use these features. As the product starts to grow and the people compositions become more complex, directly assigning users to roles would be time-consuming and a lot of hassle, so by using the user group, the admin can group the users based on human organizational structure. For example, the groups can be divided into departments, offices, and companies.`}</p>
    <figure>{`
  `}<img parentName="figure" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/user-management/user-group-model.png",
        "alt": "User group model"
      }}></img>{`
  `}<figcaption parentName="figure" {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`User group model`}</figcaption>
    </figure>
    <div className="divi" />
    <h2>{`Permission management`}</h2>
    <p>{`When setting up the user management, it’s important to determine the permissions structure and it’s granularity.`}</p>
    <p>{`Permission usually consist of 3 levels:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Page permissions`}</strong>{`: Access to function or a feature.`}</li>
      <li parentName="ol"><strong parentName="li">{`Operation permissions`}</strong>{`: Access to a specific action (most commons are create, read, update, hide, and delete) on a page or within a function.`}</li>
      <li parentName="ol"><strong parentName="li">{`Data permissions`}</strong>{`: Access to specific data on a page or a section.`}</li>
    </ol>
    <figure>{`
  `}<img parentName="figure" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/user-management/level-of-permissions.png",
        "alt": "Level of permissions"
      }}></img>{`
  `}<figcaption parentName="figure" {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`Level of permissions`}</figcaption>
    </figure>
    <p>{`Acceptance Criteria for permission management:`}</p>
    <ol>
      <li parentName="ol">{`Clear documentation of resources that need permissions, such as pages, menus, data entities, and other components. Associate each resource with actions (common basic actions: create, read, update, hide, or delete).`}</li>
      <li parentName="ol">{`Provide users with roles by their access and responsibilities. The most common user roles are admins, managers, and staffs.`}</li>
      <li parentName="ol">{`Able to set permissions for each role based on the required resources that match its responsibility.`}</li>
      <li parentName="ol">{`Assign admin or a specific role to manage the permissions regularly to accomodate new features or any changes in business requirements.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      